<template>
  <b-card>
    <b-row>

      <b-col cols="12">
        <b-card>
          <b-row>
            <b-col xs="6" sm="6" md="3" lg="3">
              <b-form-checkbox
                  v-model="filterWithRating"
                  value = 4.0
                  @change="filterUsingCheckbox()"
                  switch
                  inline
              >
                Rating 4 or Less
              </b-form-checkbox>
            </b-col>
            <b-col xs="6" sm="6" md="3" lg="3">
              <b-form-checkbox
                  value = "OPERATIONAL"
                  v-model="filterWithBusinessStatus"
                  @change="filterUsingCheckbox()"
                  switch
                  inline
              >
                Status Operational
              </b-form-checkbox>
            </b-col>
            <b-col xs="6" sm="6" md="3" lg="3">
              <b-form-checkbox
                  value= "website"
                  v-model="filterWithHasWebsite"
                  @change="filterUsingCheckbox()"
                  switch
                  inline
                  name="website"
              >
                With Website
              </b-form-checkbox>

            </b-col>
            <b-col xs="6" sm="6" md="3" lg="3">
              <b-form-checkbox
                  value="nowebsite"
                  v-model="filterWithNoWebsite"
                  @change="filterUsingCheckbox()"
                  switch
                  inline
                  name="nowebsite"
              >
                No Website
              </b-form-checkbox>
            </b-col>


          </b-row>
          <br>
          <b-row>
            <b-col xs="6" sm="6" md="3" lg="3">
              <b-form-checkbox
                  value="phone"
                  v-model="filterWithPhone"
                  @change="filterUsingCheckbox()"
                  switch
                  inline
              >
                With Phone
              </b-form-checkbox>
            </b-col>
            <b-col xs="6" sm="6" md="3" lg="3">
              <b-form-checkbox
                  value="noPhone"
                  v-model="filterWithNoPhone"
                  @change="filterUsingCheckbox()"
                  switch
                  inline
              >
                No Phone
              </b-form-checkbox>
            </b-col>
            <b-col xs="6" sm="6" md="3" lg="3">
              <b-form-checkbox
                  value="email"
                  v-model="filterWithEmail"
                  @change="filterUsingCheckbox()"
                  switch
                  inline
              >
                With Email
              </b-form-checkbox>
            </b-col>
            <b-col xs="6" sm="6" md="3" lg="3">
              <b-form-checkbox
                  value="noEmail"
                  v-model="filterWithNoMail"
                  @change="filterUsingCheckbox($event)"
                  switch
                  inline
              >
                No Emails
              </b-form-checkbox>
            </b-col>

          </b-row>
          <br>
          <b-row>

            <b-col xs="6" sm="6" md="3" lg="3">
              <b-form-checkbox
                  value="phoneoremail"
                  v-model="filterPhoneOrEmail"
                  @change="filterUsingCheckbox()"
                  switch
                  inline
              >
                Phone Or Email
              </b-form-checkbox>
            </b-col>
            <b-col xs="6" sm="6" md="3" lg="3">
              <b-form-checkbox
                  value="claimed"
                  v-model="filterWithClaimed"
                  @change="filterUsingCheckbox()"
                  switch
                  inline
              >
                Claimed
              </b-form-checkbox>
            </b-col>
            <b-col xs="6" sm="6" md="3" lg="3">
              <b-form-checkbox
                  value="unclaimed"
                  v-model="filterWithUnclaimed"
                  @change="filterUsingCheckbox()"
                  switch
                  inline
              >
                Unclaimed
              </b-form-checkbox>
            </b-col>
            <b-col xs="6" sm="6" md="3" lg="3">
              <b-form-checkbox
                  value="more100"
                  v-model="filterWithMoreReviews"
                  @change="filterUsingCheckbox()"
                  switch
                  inline
              >
                100 Reviews or More
              </b-form-checkbox>
            </b-col>
            

          </b-row>
          <br>
          <b-row>

            <b-col xs="6" sm="6" md="3" lg="3">
              <b-form-checkbox
                  value="less100"
                  v-model="filterLessWithReviews"
                  @change="filterUsingCheckbox()"
                  switch
                  inline
              >
                100 Reviews or Less
              </b-form-checkbox>
            </b-col>

            <b-col xs="6" sm="6" md="3" lg="3">
              <b-form-checkbox
                  value="noReviews"
                  v-model="filterWithNoReviews"
                  @change="filterUsingCheckbox()"
                  switch
                  inline
              >
                No Reviews
              </b-form-checkbox>
            </b-col>
          </b-row>

        </b-card>
      </b-col>
    </b-row>
    <!--    End of Filter Button Group-->
    <b-row>
      <b-col cols="12" class="text-right">
        <span @click="downloadFile()" class="btn bg-primary text-light">Download Excel</span>

      </b-col>

    </b-row>
    <br>
    <b-row>
      <b-col cols="4">
        <b-row class="justify-content-start">
          <b-col md="8" class="my-1">
            <b-form-group>
              <b-input-group>
                <select
                    name="status"
                    class="form-control"
                    v-model="filterWithStatus"
                    @change="filterUsingCheckbox()"
                >
                  <option value="" selected>All</option>
                  <option value="0">Not Contacted</option>
                  <option value="1">Contacted</option>
                  <option value="2">On Process</option>
                  <option value="3">Conversation Done</option>
                </select>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="4">
        <b-row class="justify-content-start">
          <b-col md="8" class="my-1">
            
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="4">
        <b-row class="justify-content-end">
          <b-col md="8" class="my-1">
            <b-form-group>
              <b-input-group>
                <b-form-input
                    id="filterInput"
                    v-model="searchOnLead"
                    type="search"
                    placeholder="Type to Search"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>


      <b-col cols="12">
        <b-table
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          :items="items"
          :fields="fields"
          striped
          responsive
        >
          <template #cell(show_details)="row">
            <b-form-checkbox
              v-model="row.detailsShowing"
              plain
              class="vs-checkbox-con"
              @change="row.toggleDetails"
            >
              <span class="vs-checkbox">
                <span class="vs-checkbox--check">
                  <i class="vs-icon feather icon-check" />
                </span>
              </span>
              <a id="toggleDetails" >
                <span class="vs-label">{{
                    row.detailsShowing ? "Hide" : 'Show'
                  }}</span>
              </a>

            </b-form-checkbox>
          </template>

          <template #row-details="row">
            <b-card>
              <b-row class="mb-2">
                <b-col md="4" class="mb-1">
                  <strong>Name : </strong>{{ row.item.name }}
                  <btn v-if="row.item.claimed_status != null" class="btn btn-primary">{{ row.item.claimed_status }}</btn>
                </b-col>
                <b-col md="4" class="mb-1">
                  <strong>Website : </strong
                  ><span v-if="row.item.website != null"
                    ><a :href="row.item.website" target="_blank">{{
                      row.item.website
                    }}</a></span
                  >
                  <span v-else>{{ "Not Available" }}</span>
                </b-col>
                <b-col md="4" class="mb-1">
                  <strong>Phone : </strong
                  ><span v-if="row.item.phone != null"
                    ><a :href="`tel:${row.item.phone}`">{{
                      row.item.phone
                    }}</a></span
                  >
                  <span v-else>{{ "Not Available" }}</span>
                </b-col>

                <b-col md="4" class="mb-1">
                  <strong>Email : </strong
                  ><span v-if="row.item.email != null"
                    ><a :href="`mailto:${row.item.email}`">{{
                      row.item.email
                    }}</a></span
                  >
                  <span v-else>{{ "Not Available" }}</span>
                </b-col>


                <b-col md="4" class="mb-1">
                  <strong>Address : </strong>{{ row.item.address }}
                </b-col>
                <b-col md="4" class="mb-1">
                  <strong>Rating : </strong>{{ row.item.rating }}
                </b-col>
                <b-col md="4" class="mb-1">
                  <strong>UTC Offset : </strong>{{ row.item.utc_offset }}
                </b-col>
                <b-col md="4" class="mb-1">
                  <strong>Business Status : </strong
                  >{{ row.item.business_status }}
                </b-col>
                <b-col md="4" class="mb-1">
                  <strong>Total User Rated : </strong
                  >{{ row.item.user_ratings_total }}
                </b-col>
                <b-col md="4" class="mb-1">
                  <strong>Location : </strong
                  ><span v-if="row.item.location != null"
                    ><a :href="row.item.location" target="_blank">{{
                      row.item.location
                    }}</a></span
                  >
                  <span v-else>{{ "Not Available" }}</span>
                </b-col>
                <b-col md="2" class="mb-1">
                  <strong>Status : </strong>
                  <form v-on:click="updateStatus($event)">
                    <select
                      name="status"
                      class="form-control"
                      v-if="row.item.status == 0"
                    >
                      <option value="0" selected>Not Contacted</option>
                      <option value="1">Contacted</option>
                      <option value="2">On Process</option>
                      <option value="3">Conversation Done</option>
                    </select>

                    <select
                      name="status"
                      class="form-control"
                      v-if="row.item.status == 1"
                    >
                      <option value="0">Not Contacted</option>
                      <option value="1" selected>Contacted</option>
                      <option value="2">On Process</option>
                      <option value="3">Conversation Done</option>
                    </select>

                    <select
                      name="status"
                      class="form-control"
                      v-if="row.item.status == 2"
                    >
                      <option value="0">Not Contacted</option>
                      <option value="1">Contacted</option>
                      <option value="2" selected>On Process</option>
                      <option value="3">Conversation Done</option>
                    </select>

                    <select
                      name="status"
                      class="form-select form-control margin-left"
                      v-if="row.item.status == 3"
                    >
                      <option value="0">Not Contacted</option>
                      <option value="1">Contacted</option>
                      <option value="2">On Process</option>
                      <option value="3" selected>Conversation Done</option>
                    </select>
                  </form>
                </b-col>
                <b-col md="2" class="mb-1 mt-2">
                  <button
                    type="submit"
                    v-on:click="onClick($event, row.item.id)"
                    class="btn btn-primary float-right"
                  >
                    Change
                  </button>
                </b-col>
              </b-row>

              <b-button
                size="sm"
                variant="outline-secondary"
                @click="row.toggleDetails"
              >
                Hide Details
              </b-button>
            </b-card>
          </template>

          <template #cell(avatar)="data">
            <b-avatar :src="data.value" />
          </template>

          <template #cell(status)="data">
            <b-badge :variant="status[1][data.value]">
              {{ status[0][data.value] }}
            </b-badge>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BTable,
  BFormCheckbox,
  BButton,
  BCard,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BFormInput,
  BFormGroup,
  BInputGroup,
} from "bootstrap-vue";
import exportFromJSON from "export-from-json";

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BFormInput,
    BFormGroup,
    BInputGroup,
  },
  data() {
    return {
      games: [
        {'value': 1, 'text': 'Game 1'},
        {'value': 4, 'text': 'Game 4'}
      ],

      filterWithRating: "",
      filterWithBusinessStatus: "",
      filterWithHasWebsite: "",
      filterWithNoWebsite: "",
      filterWithPhone: "",
      filterWithNoPhone: "",
      filterWithEmail: "",
      filterWithNoMail: "",
      filterPhoneOrEmail: "",
      filterWithClaimed: "",
      filterWithUnclaimed: "",
      filterWithMoreReviews: "",
      filterLessWithReviews: "",
      filterWithNoReviews: "",

      filterWithStatus: "",
      filterWithCampaign: "",
      searchOnLead: "",

      originalData:[],
      campaignList : [],
      filterCheckbox: [],
      filterStatus : [],
      fields: [
        "show_details",
        "name",
        "business_status",
        "rating",
        "address",
        { key: "status", label: "Status" },
      ],
      items: [],
      status: [
        {
          0: "Not Contacted",
          1: "Contacted",
          2: "On-Process",
          3: "Conversation Done",
        },
        {
          0: "light-danger",
          1: "light-success",
          2: "light-primary",
          3: "light-info",
        },
      ],
      delayTimer: null,
      filter: null,
      filterOn: [],
    };
  },


  watch: {
    searchOnLead: function (val){
      this.filterUsingCheckbox();
    }
  },

  async created() {
    await this.showAllData();
  },
  mounted(){
    this.getCampaignData()
  },
  methods: {
    downloadFile() {

      let excelData =[];

      this.items.forEach((item,index)=>{
        let s = {
          Name : item.name,
          Address : item.address,
          Rating : item.rating,
          Business_Status : item.business_status,
          Total_User_Rated : item.user_ratings_total,
          Website : item.website,
          Phone : item.phone,
          Email : item.email

        }
        excelData.push(s);
      });
      const data = excelData;
      const fileName = "Leads";
      const exportType = exportFromJSON.types.xls;
      if (data) exportFromJSON({ data, fileName, exportType });
    },
    async getCampaignData() {
      const response = await this.$api.get("/api/campaigns-info");
      this.campaignList = response["data"]["data"];


    },
    
    filterUsingCheckbox(){

     this.showAllData({
        filterWithRating: this.filterWithRating,
        filterWithBusinessStatus: this.filterWithBusinessStatus ?? "",
        filterWithHasWebsite: this.filterWithHasWebsite,
        filterWithNoWebsite: this.filterWithNoWebsite,
        filterWithPhone: this.filterWithPhone,
        filterWithNoPhone: this.filterWithNoPhone,
        filterWithEmail: this.filterWithEmail,
        filterWithNoMail: this.filterWithNoMail,
        filterPhoneOrEmail: this.filterPhoneOrEmail,
        filterWithClaimed: this.filterWithClaimed,
        filterWithUnclaimed: this.filterWithUnclaimed,
        filterWithMoreReviews: this.filterWithMoreReviews,
        filterLessWithReviews: this.filterLessWithReviews,
        filterWithNoReviews: this.filterWithNoReviews,

        filterWithStatus: this.filterWithStatus,
        filterWithCampaign: this.filterWithCampaign,
        searchOnLead: this.searchOnLead,
     });

    },
    updateStatus(e) {
      this.updateId = e.target.value;
    },
    async showAllData(params) {
      const response = await this.$api.get(
        "/api/campaign-leads/" + this.$route.params.id, {
          params: {
            filterWithRating: params?.filterWithRating,
            filterWithBusinessStatus: params?.filterWithBusinessStatus,
            filterWithHasWebsite: params?.filterWithHasWebsite,
            filterWithNoWebsite: params?.filterWithNoWebsite,
            filterWithPhone: params?.filterWithPhone,
            filterWithNoPhone: params?.filterWithNoPhone,
            filterWithEmail: params?.filterWithEmail,
            filterWithNoMail: params?.filterWithNoMail,
            filterPhoneOrEmail: params?.filterPhoneOrEmail,
            filterWithClaimed: params?.filterWithClaimed,
            filterWithUnclaimed: params?.filterWithUnclaimed,
            filterWithMoreReviews: params?.filterWithMoreReviews,
            filterLessWithReviews: params?.filterLessWithReviews,
            filterWithNoReviews: params?.filterWithNoReviews,

            filterWithStatus: params?.filterWithStatus,
            filterWithCampaign: params?.filterWithCampaign,
            q: params?.searchOnLead,
          
          }
        }
      );

      this.items = response["data"]["data"];
      this.originalData = this.items;
    },
    onClick(e, value) {
      e.preventDefault();
      const res = this.$api.post(`/api/lead-update`, {
        lead_id: this.updateId,
        update_id: value,
      });

      this.filterUsingCheckbox();


      if (res) {
        this.filterUsingCheckbox();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "BellIcon",
            variant: "success",
            text: "Status successfully Updated",
          },
        });
        this.filterUsingCheckbox();
      }
    },
    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
      }, 1000);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
